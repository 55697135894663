<template>
	<div v-loading="loading">
		<div class="item-block">
			<div class="payment-detail">
				<div class="payment-media">
					<el-row>
						<el-col :span="4">
							<div class="media-left"><i class="el-icon-circle-check ns-text-color"></i></div>
						</el-col>

						<el-col :span="16">
							<div class="media-body">
								<el-row>
									<el-col :span="12">
										<div class="payment-text">您的订单已提交成功，正在等待处理！</div>
										<div>
											<span>应付金额：</span>
											<span class="payment-money ns-text-color">￥{{ payInfo.pay_money }}元</span>
										</div>
									</el-col>
									<el-col :span="12"></el-col>
								</el-row>
							</div>
						</el-col>

						<el-col :span="4">
							<div class="media-right">
								<div class="el-button--text" @click="orderOpen ? (orderOpen = false) : (orderOpen = true)">
									订单信息
									<i :class="orderOpen ? 'rotate' : ''" class="el-icon-arrow-down"></i>
								</div>
							</div>
						</el-col>
					</el-row>
				</div>

				<div class="order-info" v-if="orderOpen">
					<el-row>
						<el-col :span="4" class="order-info-left"></el-col>
						<el-col :span="20">
							<div class="line"></div>
							<div class="order-item">
								<div class="item-label">交易单号：</div>
								<div class="item-value">{{ payInfo.out_trade_no }}</div>
							</div>
							<div class="order-item">
								<div class="item-label">订单内容：</div>
								<div class="item-value">{{ payInfo.pay_detail }}</div>
							</div>
							<div class="order-item">
								<div class="item-label">订单金额：</div>
								<div class="item-value">￥{{ payInfo.pay_money }}</div>
							</div>
							<div class="order-item">
								<div class="item-label">创建时间：</div>
								<div class="item-value">{{ $timeStampTurnTime(payInfo.create_time) }}</div>
							</div>
						</el-col>
					</el-row>
				</div>
			</div>
		</div>

		<div class="item-block">
			<div class="block-text">支付方式</div>
			<div class="pay-type-list" v-if="payTypeList.length">
				<div class="pay-type-item" v-for="(item, index) in payTypeList" :key="index" :class="payIndex == index ? 'active' : ''"
				 @click="payIndex = index">
					{{ item.name }}
				</div>
				<div class="clear"></div>
			</div>
			<div class="no-pay-type" v-else>
				<p>商家未配置支付方式</p>
			</div>
		</div>

		<div class="item-block" v-if="payTypeList.length">
			<div class="order-submit">
				<el-button type="primary" class="el-button--primary" @click="pay">立即支付</el-button>
			</div>
			<div class="clear"></div>
		</div>

		<el-dialog title="请确认支付是否完成" :visible.sync="dialogVisible" width="23%" top="30vh" class="confirm-pay-wrap">
			<div class="info-wrap">
				<i class="el-message-box__status el-icon-warning"></i>
				<span>完成支付前请根据您的情况点击下面的按钮</span>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="goIndex" size="small">返回首页</el-button>
				<el-button type="primary" @click="goOrderList" size="small">已完成支付</el-button>
			</span>
		</el-dialog>

		<!-- 微信支付弹框 -->
		<el-dialog title="请用微信扫码支付" :visible.sync="openQrcode" width="300px" center>
			<div class="wechatpay-box"><img :src="payQrcode" /></div>
		</el-dialog>
		
		<el-dialog  :visible.sync="upload_payment" :show-close="true" class="add_dialog">
			<div class="add_content">
				<div class="heli-title">
					合利宝支付信息
				</div>
				<div class="condition">
					<div class="condition_item" v-if="heliPayInfo.order_amount">
						<div class="left">
							<div class="name">订单金额：</div>
						</div>
						<div class="right">
							<span ref="copyMoney" class="heli-tit">{{heliPayInfo.order_amount}}</span>
							<span class="copy-btn" @click="copyAccount('copyMoney')">点击复制</span>
						</div>
					</div>
					<div class="condition_item" v-if="bankList">
						<div class="left">
							<div class="name">选择银行：</div>
						</div>
						<div class="right">
							<el-select v-model="bankvalue" placeholder="请选择" :value-key="bankKey">
								<el-option
								  v-for="item in bankList"
								  :key="item"
								  :label="bankList[item]"
								  :value="item">
								</el-option>
							  </el-select>
						</div>
					</div>
					
					
					
					
					
					<!-- <div class="condition_item" v-if="heliPayInfo.order_no">
						<div class="left">
							<div class="name">商户订单号：</div>
						</div>
						<div class="right">{{heliPayInfo.order_no}}</div>
					</div>
					<div class="condition_item" v-if="heliPayInfo.order_amount">
						<div class="left">
							<div class="name">订单金额：</div>
						</div>
						<div class="right">
							<span ref="copyMoney" class="heli-tit">{{heliPayInfo.order_amount}}</span>
							<span class="copy-btn" @click="copyAccount('copyMoney')">点击复制</span>
						</div>
					</div>
					<div class="condition_item" v-if="heliPayInfo.large_payment_no">
						<div class="left">
							<div class="name">收款方账号：</div>
						</div>
						<div class="right" >
							<span ref="copysNo">{{heliPayInfo.large_payment_no}}</span>
							<span class="copy-btn" @click="copyAccount('copysNo')">点击复制</span>
						</div>
					</div>
					<div class="condition_item" v-if="heliPayInfo.receiver_name">
						<div class="left">
							<div class="name">收款方名称：</div>
						</div>
						<div class="right" >
							<span ref="copyName">{{heliPayInfo.receiver_name}}</span>
							<span class="copy-btn" @click="copyAccount('copyName')">点击复制</span>
						</div>
					</div>
					<div class="condition_item" v-if="heliPayInfo.opening_location">
						<div class="left">
							<div class="name">开户地：</div>
						</div>
						<div class="right">
							<span ref="copyAddress">{{heliPayInfo.opening_location}}</span>
							<span class="copy-btn" @click="copyAccount('copyAddress')">点击复制</span>
						</div>
					</div>
					<div class="condition_item" v-if="heliPayInfo.branch_branches">
						<div class="left">
							<div class="name">分支行：</div>
						</div>
						<div class="right">
							<span ref="copyFen">{{heliPayInfo.branch_branches}}</span>
							<span class="copy-btn" @click="copyAccount('copyFen')">点击复制</span>
						</div>
					</div>
					<div class="condition_item" v-if="heliPayInfo.branch_name">
						<div class="left">
							<div class="name">银行名称：</div>
						</div>
						<div class="right">
							<span ref="copyBankName">{{heliPayInfo.branch_name}}</span>
							<span class="copy-btn" @click="copyAccount('copyBankName')">点击复制</span>
						</div>
					</div>
					<div class="condition_item" v-if="heliPayInfo.complete_date">
						<div class="left">
							<div class="name">支付时间：</div>
						</div>
						<div class="right">
							<span ref="copyBankName">{{$timeStampTurnTime(heliPayInfo.complete_date)}}</span>
							<span class="copy-btn" @click="copyAccount('copyBankName')">点击复制</span>
						</div>
					</div>
					<div class="condition_item upload ">
						<div class="left">
							<div class="name heli-tit">请复制银行转账所需信息，进行转账流程</div>
						</div>
					</div> -->
					<div class="bottom_btn">
						<div class="btn"  @click="goPay()">
							去支付
						</div>
						<div class="btn" @click="upload_payment = false">我已支付<div class="tips">(请耐心等待审核)</div></div>
					</div>
				</div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import {
		getPayInfo,
		getPayType,
		checkPayStatus,
		pay,
		onlineinterface,
		payType
	} from '@/api/pay';
	import Config from '@/utils/config'
	export default {
		name: 'pay',
		components: {},
		data: () => {
			return {
				bankKey:'PINGAN',
				bankvalue:'平安银行',
				 bankList: [],
				heliPayInfo:{},
				orderOpen: false,
				outTradeNo: '',
				payInfo: {
					pay_money: 0
				},
				payIndex: 0,
				payTypeList: [{
						name: '支付宝支付',
						icon: 'iconzhifubaozhifu-',
						type: 'alipay'
					},
					{
						name: '微信支付',
						icon: 'iconweixinzhifu',
						type: 'wechatpay'
					},
					{
						name: '合利宝支付',
						icon: 'iconweixinzhifu',
						type: 'helipay'
					}
				],
				payUrl: '',
				timer: null,
				payQrcode: '',
				openQrcode: false,
				loading: true,
				test: null,
				dialogVisible: false,
				upload_payment:false,
			};
		},
		created() {
			if (!this.$route.query.code) {
				this.$router.push({
					path: '/'
				});
				return;
			}
			this.outTradeNo = this.$route.query.code;
			this.getPayInfo();
			this.getPayType();
			this.checkPayStatus();
			// this.getHeliInfo()
			this.getBankpayType()
			this.getInfo()
		},
		methods: {
			getInfo(){
				for(let i in this.bankList){
					if(this.bankvalue == this.bankList[i]){
						this.bankKey = i
					}
				}
				onlineinterface({
					P5_bankId:this.bankKey,
					out_trade_no: this.outTradeNo
				})
				.then(res=>{
					if(res.code >= 0){
						this.heliPayInfo = res.data;
					}else{
						this.$message.error(res.message);
					}
				})
				.catch((res)=>{
					// this.$message.error(res.message);
				})
			},
			goPay(){
				window.open(Config.baseUrl + this.heliPayInfo.pay_url, '_blank')
			},
			getBankpayType(){
				payType()
				.then(res=>{
					this.bankList = res
				})
				.catch((e)=>{
					this.bankList = e
				})
			},
			closePayInfo(){
				this.upload_payment = false;
				this.$router.push('/member/payment_list')
			},
			copyAccount(str) {
			  const copyElement = this.$refs[str]; // 获取要复制的元素
				  if (navigator.clipboard) {
					navigator.clipboard.writeText(copyElement.innerText); // 使用新版API直接写入剪贴板
					this.$message({message:'复制成功',type:'success'})
				  } else {
					let range = document.createRange();
					range.selectNodeContents(copyElement); // 选择要复制的节点内容
					
					window.getSelection().removeAllRanges(); // 清除之前的选区
					window.getSelection().addRange(range); // 添加新的选区
					
					try {
					  document.execCommand("copy"); // 执行复制命令
					} catch (err) {
					  console.error('无法复制', err); // 输出错误信息
					} finally {
					  window.getSelection().removeAllRanges(); // 移除选区
					}
				  }
			},
			// getHeliInfo(){
			// 	helipay({
			// 		out_trade_no: this.outTradeNo
			// 	})
			// 	.then(res => {
			// 		const {
			// 			code,
			// 			message,
			// 			data
			// 		} = res;
			// 		if (data) {
			// 			this.heliPayInfo = res.data;
			// 		}
			// 		this.loading = false;
			// 	})
			// 	.catch(err => {
			// 		this.loading = false;
			// 		this.$message.error({
			// 			message: err.message,
			// 			duration: 2000,
			// 			onClose: () => {
			// 				// this.$router.push({
			// 				// 	path: '/member/order_list'
			// 				// });
			// 			}
			// 		});
			// 	});
			// },
			
			getPayInfo() {
				getPayInfo({
						out_trade_no: this.outTradeNo,
						forceLogin: true
					})
					.then(res => {
						const {
							code,
							message,
							data
						} = res;
						if (data) {
							this.payInfo = res.data;
						}
						this.loading = false;
					})
					.catch(err => {
						this.loading = false;
						this.$message.error({
							message: err.message,
							duration: 2000,
							onClose: () => {
								this.$router.push({
									path: '/member/order_list'
								});
							}
						});
					});
			},
			getPayType() {
				getPayType({})
					.then(res => {
						const {
							code,
							message,
							data
						} = res;
						if (code >= 0) {
							if (res.data.pay_type == '') {
								this.payTypeList = [];
							} else {
								this.payTypeList.forEach((val, key) => {
									if (res.data.pay_type.indexOf(val.type) == -1) {
										this.payTypeList.splice(key, 1);
									}
								});
							}
						}
					})
					.catch(err => {
						this.$message.error(err.message);
					});
			},
			checkPayStatus() {
				this.timer = setInterval(() => {
					checkPayStatus({
							out_trade_no: this.outTradeNo
						})
						.then(res => {
							const {
								code,
								message,
								data
							} = res;
							if (code >= 0) {
								if (code == 0) {
									if (data.pay_status == 2) {
										clearInterval(this.timer);
										this.dialogVisible = false;
										this.$router.push({
											path: '/result?code=' + this.payInfo.out_trade_no
										});
									}
								} else {
									clearInterval(this.timer);
								}
							}
						})
						.catch(err => {
							clearInterval(this.timer);
							this.$router.push({
								path: '/'
							});
						});
				}, 1000);
			},
			pay() {
				var payType = this.payTypeList[this.payIndex];
				if (!payType) return;
				if(payType.type == 'helipay'){
					this.upload_payment = true;
					return;
				}
				if (payType.type == 'alipay') var newWindow = window.open();
				pay({
					out_trade_no: this.payInfo.out_trade_no,
					pay_type: payType.type,
					app_type: 'pc'
				})
				.then(res => {
					const {
						code,
						message,
						data
					} = res;
					if (code >= 0) {
						switch (payType.type) {
							case 'alipay':
								this.payUrl = res.data.data;
								newWindow.location.href = this.payUrl;
								this.open();
								break;
							case 'wechatpay':
								this.payQrcode = res.data.qrcode;
								this.openQrcode = true;
								break;
						}
					} else {
						this.$message({
							message: message,
							type: 'warning'
						});
					}
				})
				.catch(err => {
					this.$message.error(err.message);
				});
			},
			open() {
				this.dialogVisible = true;
			},
			goIndex() {
				clearInterval(this.timer);
				this.dialogVisible = false;
				this.$router.push({
					path: '/'
				});
			},
			goOrderList() {
				clearInterval(this.timer);
				this.dialogVisible = false;
				this.$router.push({
					path: '/member/order_list'
				});
			}
		}
	};
</script>

<style lang="scss" scoped>
	.clear {
		clear: both;
	}

	.item-block {
		padding: 0 15px 1px;
		margin: 10px 0;
		border-radius: 0;
		border: none;
		background: #ffffff;

		.block-text {
			border-color: #eeeeee;
			color: $ns-text-color-black;
			padding: 7px 0;
			border-bottom: 1px;
		}
	}

	.media-left {
		text-align: center;

		i {
			font-size: 65px;
		}
	}

	.payment-detail {
		padding: 30px 0;
		transition: 2s;
	}

	.media-right {
		text-align: center;
		line-height: 65px;
		cursor: pointer;

		i.rotate {
			transform: rotate(180deg);
			transition: 0.3s;
		}
	}

	.payment-text {
		font-size: 20px;
	}

	.payment-time {
		font-size: 12px;
		line-height: 65px;
		color: #999;
	}

	//支付方式
	.order-submit {
		float: right;
		padding: 10px;
	}

	.pay-type-list {
		padding: 20px 0;
	}

	.pay-type-item {
		display: inline-block;
		border: 2px solid #eeeeee;
		padding: 5px 20px;
		margin-right: 20px;
		cursor: pointer;
	}

	.pay-type-item.active {
		border-color: $base-color;
	}

	.mobile-wrap {
		width: 300px;
	}

	.order-info {
		.order-item {
			padding: 1px 0;

			.item-label {
				display: inline-block;
				width: 100px;
			}

			.item-value {
				display: inline-block;
			}
		}

		.line {
			width: 100%;
			height: 1px;
			background: #f2f2f2;
			margin: 20px 0 10px 0;
		}

		.order-info-left {
			height: 1px;
		}
	}

	.wechatpay-box {
		text-align: center;

		img {
			width: 80%;
		}
	}

	.confirm-pay-wrap {
		.el-dialog__body {
			padding: 10px 15px;
		}

		.info-wrap {
			i {
				position: initial;
				vertical-align: middle;
				transform: initial;
			}

			span {
				vertical-align: middle;
				padding: 0 10px;
			}
		}
	}
</style>
<style lang="scss">
	.confirm-pay-wrap {
		.el-dialog__body {
			padding: 10px 15px;
		}

		.el-dialog__footer {
			padding-top: 0;
			padding-bottom: 10px;
		}
	}
	.add_dialog {
			.el-dialog__body{
				padding: 0;
				.add_content{
					padding: 0px 44px 41px;
					.tit{
						font-size: 15px;
						line-height: 22px;
						font-weight: 600;
						margin-bottom: 24px;
					}
					.bottom_btn{
						display: flex;
						align-items: center;
						justify-content: center;
						.btn{
							margin-left: 30px;
							cursor: pointer;
							display: flex;
							align-items: baseline;
							justify-content: center;
							border-radius: 5px;
							background-color: #FF4646;
							color: #fff;
							font-size: 16px;
							line-height: 22px;
							padding: 10px 0;
							padding: 10px 16px;
							text-align: center;
							.tips{
								font-size: 14px;
								color: #fff;
								margin-left: 5px;
							}
						}
					}
					.condition{
						padding-top: 10px;
						padding-left: 10px;
						.condition_item{
							display: flex;
							align-items: center;
							margin-bottom: 20px;
							&.upload{
								align-items: flex-start;
							}
							.left{
								min-width: 105px;
								display: flex;
								align-items: center;
								color: #E02020;
								font-size: 12px;
								line-height: 16px;
								margin-right: 2px;
								margin-right: 10px;
								
								.name{
									margin-left: 5px;
									color: #222;
									font-size: 12px;
									line-height: 16px;
								}
							}
							.right{
								flex:1;
								font-size: 14px;
								line-height: 20px;
								.upload-img{
									line-height: 1;
									.add_img{
										border: 1px dashed #dfdfdf;
										padding: 20px 12px;
										display: flex;
										align-items: center;
										flex-direction: column;
										font-size: 14px;
										.el-icon-plus{
											margin-bottom: 10px;
											font-size: 16px;
										}
									}
									.img-wrap{
										img{
											display: block;
											border-radius: 5px;
											width: 100%;
										}
									}
								}
							}
						}
					}
					.btns{
						margin-top: 12px;
						display: flex;
						align-items: center;
						padding-left: 104px;
						.btn{
							cursor: pointer;
							margin-right: 24px;
							color: #333;
							font-size: 14px;
							line-height: 16px;
							padding: 9px 20px;
							border-radius: 2px;
							border: 1px solid #DBDCDC;
							&.save{
								background-color: #007AFF;
								color: #fff;
								border-color: #007AFF;
							}
						}
					}
				}
			}
		}
		.heli-title{
			font-size: 16px;
			font-weight: bold;
			text-align: center;
		}
		.heli-tit{
			color:red !important;
			font-size:15px !important
		}
		.copy-btn{
			cursor: pointer;
			font-size: 10px;
			border: 1px solid #13a5ff;
			padding: 3px 2px;
			border-radius: 3px;
			color: #13a5ff;
			margin-left: 15px;
		}
</style>
